import { loggedIn } from 'bv-helpers/session';
import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export default (componentId) => (
  getJSON(`/sportsbook_components/home_components/components/${componentId}`, {
    c: locale(),
    l: loggedIn(),
    is_casino_component: true,
  }).catch(() => ({}))
);
