import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'bv-components';
import { useLoggedIn } from 'bv-hooks';
import fetchComponent from '../api';
import { getSkeletonType } from '../../../home_components/helpers';

export default (WrappedComponent) => {
  const WithComponentData = (props) => {
    const { componentId, isHomeComponent, type } = props;
    const [component, setComponent] = useState();
    const loggedIn = useLoggedIn();

    useEffect(() => {
      // Right components already include the component payload
      if (isHomeComponent) {
        setComponent(null);
        fetchComponent(componentId).then(setComponent);
      }
    }, [componentId, loggedIn]);

    if (!isHomeComponent) return <WrappedComponent {...props} />;
    if (!component) return <Skeleton skeletonType={getSkeletonType(type)} />;
    if (isHomeComponent && !component.payload) return null;

    const { payload } = component;

    const tracking = {
      type,
      path: 'home_components',
      label: payload.label,
      category: payload.category,
      gameSearchCategoryId: payload.gameSearchCategoryId,
    };

    return (
      <WrappedComponent {...props} {...component} className="home-components--casino" tracking={tracking} />
    );
  };

  WithComponentData.propTypes = {
    componentId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    isHomeComponent: PropTypes.bool,
  };

  WithComponentData.defaultProps = {
    isHomeComponent: false,
  };

  WithComponentData.displayName = 'withComponentData';

  return WithComponentData;
};
