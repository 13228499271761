import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { useGames } from '../hooks';

const { store } = window.reduxState;

const pickGameInstanceFields = (items) => items.map((game) => ({
  gameId: game.id,
  ribbon: game.ribbon,
  tileStyle: game.tileStyle,
  useAnimation: game.useAnimation,
}));

export default (WrappedComponent) => {
  const WithGamesList = ({
    payload: {
      games,
      ...rest
    },
    componentId,
    className,
    ...restProps
  }) => {
    const gamesIds = useGames(games);
    if (!gamesIds) return null;

    return (
      <Provider store={store}>
        <div className={`${className ? `${className}-wrapper ` : ''}`}>
          <section>
            <WrappedComponent
              {...rest}
              games={gamesIds}
              gameInstances={pickGameInstanceFields(games)}
              id={`home-component-${componentId}`}
              className={className}
              {...restProps}
            />
          </section>
        </div>
      </Provider>
    );
  };

  WithGamesList.propTypes = {
    payload: PropTypes.shape({
      games: PropTypes.instanceOf(Array).isRequired,
    }).isRequired,
    componentId: PropTypes.number.isRequired,
    className: PropTypes.string,
  };

  WithGamesList.defaultProps = {
    className: '',
  };

  WithGamesList.displayName = 'withGamesList';

  return WithGamesList;
};
