import { GamesSlider } from 'casino-components';
import PropTypes from 'prop-types';

const VideoGamesListComponent = (props) => (
  <GamesSlider {...props} isVideoTile />
);

VideoGamesListComponent.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

VideoGamesListComponent.defaultProps = {
  icon: 'casino',
};

export default VideoGamesListComponent;
