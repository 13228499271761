import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'bv-components';
import Context from 'casino-context';
import { getCategoryPath } from 'casino-helpers';
import { t } from 'bv-i18n';

const Widget = ({ payload: { url, categorySlug }, className, title }) => {
  const { slug } = useContext(Context);

  const showTitle = () => {
    if (!title) return '';

    return (
      <span className="bvs-header">{t('javascript.casino.daily_jackpots')}</span>
    );
  };

  return (
    <div className={`${className ? `${className}-wrapper ` : ''}widget-component`}>
      {showTitle()}

      <div className="casino-game-widget">
        {categorySlug && (
          <Link to={getCategoryPath(categorySlug, slug)} data-react="true" />
        )}
        <iframe src={url} />
      </div>
    </div>
  );
};

Widget.propTypes = {
  payload: PropTypes.shape({
    url: PropTypes.string.isRequired,
    categorySlug: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.bool,
};

Widget.defaultProps = {
  className: '',
  title: false,
};

export default Widget;
