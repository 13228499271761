import { t } from 'bv-i18n';
import { handleCta } from 'bv-services';
import { GamesSlider, GamesList } from 'casino-components';
import { Button } from 'bv-components';
import PropTypes from 'prop-types';

const ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

const GamesListComponent = ({ action, ...props }) => {
  const {
    orientation, label, icon, liveCasinoGame,
  } = props;
  return (
    <>
      {orientation === ORIENTATION.HORIZONTAL
        ? <GamesSlider {...props} icon={icon} liveCasinoGame={liveCasinoGame} />
        : <GamesList {...props} label={label} />}
      {action && (
        <div className="button-wrapper">
          <Button
            primary
            onClick={() => handleCta(action)}
          >
            {t('javascript.show_full_list')}
          </Button>
        </div>
      )}
    </>
  );
};

GamesListComponent.propTypes = {
  action: PropTypes.instanceOf(Object),
  orientation: PropTypes.oneOf(Object.values(ORIENTATION)),
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  liveCasinoGame: PropTypes.bool,
};

GamesListComponent.defaultProps = {
  action: null,
  orientation: ORIENTATION.HORIZONTAL,
  icon: 'casino',
  liveCasinoGame: false,
};

export default GamesListComponent;
